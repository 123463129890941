// form validation methods, adding custom method
//add method function needs three attributes (name, method, message)
$("document").ready(function(){
	jQuery.validator.addMethod(
		"moreThan",
		function(value, element, param) {
			// return this.value > params;
			// var minParmValue = $(param).val();
			/*changed to use attribute name istead of id*/
			var minParmValue = $('[name="'+ param +'"]').val();
			if( value === 0 ){ minParmValue = minParmValue - 1 ;} //jogad
			return this.optional(element) || (parseFloat(value) > minParmValue);
		},
		"High must be more than Low."
		//jQuery.format("High must be more than Low {0}.")
	);

	jQuery.validator.addMethod(
		"ageGrtThan",
		function(value, element, param) {
			// return this.value > params;
			// var minParmValue = $(param).val();
			/*changed to use attribute name istead of id*/
			var minParmValue = $('[name="'+ param +'"]').val();

			return this.optional(element) || (parseFloat(value) > minParmValue);
		},
		"Retirement age must be more than age."
	);

	jQuery.validator.addMethod("linkedinDate",
		function(value, element) {
			if(value.length === 0) { return true;}
			/*working
			 The above code validates the date for year 1000-2999. Don't forget to set the maxlength of text box to 7.
			 */
			var filter = new RegExp("(0[123456789]|10|11|12)([/])([1-2][0-9][0-9][0-9])");

			// var filter = new RegExp("^(0[1-9]|1[0-2])[/](19|20)?\d{2}$/");
			// var filter = new RegExp("(0[1-9]|1[0-2])[/](19|20)?\d{2}");

			if(filter.test(value))	{return true;}
			else	{return false;}

		},
		"Enter MM/YYYY"
	);
// add method ends
}); //document ready