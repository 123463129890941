// form validation methods, adding custom method
		//add method function needs three attributes (name, method, message)
$("document").ready(function(){
jQuery.validator.messages = {
        required: "Required Field",
        remote: "Input Error",
        email: "Invalid Email",
        url: "Invalid URL",
        date: "Invalid Date",
        dateISO: "Invalid date (ISO)",
        number: "Invalid Number",
        digits: "Enter Digits only",
        creditcard: "Please enter a valid credit card number.",
        equalTo: "Enter same value",
        accept: "Please enter a value with a valid extension.",
        maxlength: $.validator.format("Enter {0} characters or less"),
        minlength: $.validator.format("Enter {0} characters or more"),
        rangelength: $.validator.format("Please enter a value between {0} and {1} characters long."),
        range: $.validator.format("Value between {0} and {1}"),
        max: $.validator.format("Maximum value {0}"),
        min: $.validator.format("Minimum value {0}")
};
jQuery.validator.setDefaults({
        errorClass: "error-block",
        validClass: "valid",
        errorElement: "p"
    }
);
}); //document ready